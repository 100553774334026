import styled from "styled-components";
import { color } from "../../theme/Color";

export const OnboardingMainContainer = styled.div`
  display: flex;
  height: 100vh;
  font-family: Inter;
  font-style: normal;
  text-align: center;

  & .mainContainer {
    border: 1px solid #262626;
    padding: 32px;
    border-radius: 15px;
  }

  & .header {
    margin-top: 24px;
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    color: ${color._efefef};
  }

  & .text {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 32px;
    color: ${color._d0d0d0};
  }
  & .logoutText {
    font-size: 16px;
    font-weight: 400;
    color: ${color._d0d0d0};
  }
  & .keyIcon {
    width: 24px;
    height: 24px;
  }

  & .onboardingBtn {
    width: 100%;
    margin-top: 24px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    text-transform: none;
  }

  & .loginHelpBtn {
    width: 100%;
    margin-top: 24px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    background-color: #131313;
    border: 1px solid #0069d7;
    text-transform: capitalize;
  }
`;
